import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router";
import Loading from "./Components/Loading/Loading";
import Randomizer from "./Components/Randomizer/Randomizer";
import { Permission } from "./ProtectRoute/permissionRoutes";
import CTMSManagement from "./Components/CTMS/CTMSManagements";
// import PdfDesign from "./Components/Randomizer/TrialDesign/PdfDesign";

const ChangePassword = lazy(() =>
  import("./Pages/Modules/UserProfile/changePassword")
);
const PageNotFound = lazy(() =>
  import("./Components/PageNotFound/PageNotFound")
);
const LoginPage = lazy(() => import("./Pages/Modules/Login/LoginPage"));
const DataPassword = lazy(() =>
  import("./Pages/Modules/DataExtractPassword/DataPassword")
);
const ForgotPassword = lazy(() =>
  import("./Pages/Modules/Login/ForgotPassword")
);
const ResetPassword = lazy(() => import("./Pages/Modules/Login/ResetPassword"));
const ClientRole = lazy(() => import("./Pages/Roles/ClientRole/ClientRole"));
const UserRole = lazy(() => import("./Pages/Roles/UserRole/UserRole"));
const Studies = lazy(() => import("./Pages/Modules/StudyIdRole/Studies"));
const Document = lazy(() =>
  import("./Components/ETMF/Document/Documents/Document")
);

const App = () => {
  const { LoginPermission, AllPermission, UserPermission, ClientPermission, CTMSPermission, EDCPermission, ETMFPermission, RandomizePermission } =
    Permission;

    
  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const table = tableRef.current;

        if (!table) {
          console.error("Table reference is null.");
          return;
        }

        const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);

        if (!th) {
          console.error(
            `No th element found for column index: ${resizingColumn}`
          );
          return;
        }

        th.style.width = `${width}px`;
        table.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef?.current?.querySelectorAll("th")?.forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<AllPermission />}>
            <Route path="/" element={<LoginPage />} />
          </Route>

          <Route path="/login" element={<LoginPermission />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>

          <Route path="/dataPassword/:id" element={<DataPassword />} />
          {/* <Route path="/pdf" element={<PdfDesign />} /> */}

          <Route path="/forgotPassword" element={<LoginPermission />}>
            <Route path="/forgotPassword" element={<ForgotPassword />} />
          </Route>

          <Route path="/resetPassword" element={<LoginPermission />}>
            <Route path="/resetPassword" element={<ResetPassword />} />
          </Route>

          <Route path="/changePassword" element={<AllPermission />}>
            <Route path="/changePassword" element={<ChangePassword />} />
          </Route>

          <Route path="/userRole" element={<UserPermission />}>
            <Route
              path="/userRole"
              element={
                <UserRole tableRef={tableRef} onMouseDown={onMouseDown} />
              }
            />
          </Route>

          <Route path="/clientRole" element={<ClientPermission />}>
            <Route
              path="/clientRole"
              element={
                <ClientRole tableRef={tableRef} onMouseDown={onMouseDown} />
              }
            />
          </Route>

          <Route path="/studyId/:studyId" element={<EDCPermission />}>
            <Route
              path="/studyId/:studyId"
              element={<Studies tableRef={tableRef} onMouseDown={onMouseDown} />}
            />
          </Route>

          <Route path="/etmf/:studyId" element={<ETMFPermission />}>
            <Route path="/etmf/:studyId" element={<Document />} />
          </Route>
          <Route path="/iwrs/:studyId" element={<RandomizePermission />}>
            <Route path="/iwrs/:studyId" element={<Randomizer />} />
          </Route>
          <Route path="/ctms/:studyId" element={<CTMSPermission />}>
            <Route path="/ctms/:studyId" element={<CTMSManagement />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
